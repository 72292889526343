$breakpoint: 600px;
.menu-list {
  margin-left: 0;
  padding-left: 0;
  display: flex;
  flex-wrap: wrap;
  li {
    list-style-type: none;
    color: #888;
    @media (max-width: 400px) {
      
      .button-secondary {
        padding: 0;
      }
    }
  }
  .menu-item-active {
    color: #000;
  }
  .menu-long-name {
    @media (max-width: ($breakpoint + 1px)) {
      display: none;
    }
  }
  .menu-short-name {
    font-size: 10px;
    @media (min-width: $breakpoint) {
      display: none;
    }
  }
}
