@import './vars';
@media (min-width: ($mobileDesktopBreakpoint + 1px)) {
  body {
    background-color: $backdropColor;
  }
  .h-header {
    display: none !important;
  }
  .h-header-desktop {
    height: $desktopHeaderHeight;
    background-color: $ccolor;
    color: $ccolorContrast;
    display: flex;
    .h-logo {
      line-height: $desktopHeaderHeight;
      width: $desktopGridLeftWidth;
      text-align: center;
      // font-weight: bold;
      font-size: 30px;
    }
    .h-top-search {
      flex-grow: 2;
      h3 {
        display: none;
      }
      .h-search-field-holder {
        height: $desktopHeaderHeight;
        padding: 28px;
      }
      .h-search-content,
      .h-main-content {
        position: fixed;
        color: #000;
        box-shadow: 0 10px 10px #aaa;
        margin-left: 0;
        background-color: #fff;
        z-index: 4;
        overflow-y: auto;
        bottom: 0;
        top: $desktopHeaderHeight;
        right: 0;
        left: $desktopGridLeftWidth;
      }
    }
    .h-user-name-drop-down {
      width: $desktopGridRightWidth;
      display: flex;
      flex-direction: column;
      justify-content: center;
      .h-user-button {
        display: flex;
        flex-direction: row;
        justify-content: end;
        padding-right: 20px;
        cursor: pointer;
      }
      .h-user-avatar {
        transform: translateY(-2px);
        margin-right: 4px;
      }
      .h-user-name {
        margin-right: 4px;
      }
      .triangle-holder {
        transform: translateY(6px);
      }
    }
  }
  .menu-list {
    flex-direction: column;
    justify-content: start;
    position: fixed;
    left: 0;
    width: $desktopGridLeftWidth;
    top: $desktopHeaderHeight;
    bottom: 0;
    background-color: $backdropColor;
    margin-top: 0;
    overflow-x: hidden;
    overflow-y: auto;
    z-index: 3;
    flex-wrap: nowrap;
    .menu-list-item {
      background-color: #fff;
      margin: 16px 0;
      a {
        display: flex;
        align-items: center;
        text-decoration: none;
        &.menu-item-active {
          background-color: $ccolor;
          color: $ccolorContrast;
        }
        .menu-icon {
          width: 30px;
        }
        &:hover {
          background-color: $ccolorSoft;
        }
      }
    }
  }
  .h-footer {
    .menu-list {
      border-right: 2px solid #aaa;
    }
  }
  .h-main-content {
    margin-left: $desktopGridLeftWidth;
    padding-right: $desktopGridRightWidth;
    .h-main-content {
      margin-left: 0;
      padding-right: 0;
    }
  }
  .h-content,
  .page-headline,
  .h-search-content {
    margin-left: $desktopGridLeftWidth;
    margin-right: $desktopGridRightWidth;
    .h-content,
    .page-headline {
      margin-left: 0;
      margin-right: 0;
    }
  }
  .hamburgerMenu {
    z-index: 4;
    width: 30%;
    border-left: 2px solid #aaa;
    .menu-list {
      width: 100%;
      .menu-list-item,
      .w3-button.logout {
        margin: 0;
        border-bottom: 1px solid #eee;
        background-color: #fff;
        width: 100%;
        a {
          padding: 16px;
        }
      }
      .w3-button.logout {
        padding: 16px;
        text-align: left;
      }
    }
    transform: translate(100%, 0);
    &.openHamburgerMenu {
      transform: translate(0, 0%);
      transition: .2s transform ease-out;
    }
  }

  .padding-desktop {
    padding-left: 16px;
    padding-right: 16px;
  }
  .RightDrawer {
    width: $desktopGridRightWidth;
  }
}