@import '../../static/styles/vars.scss';
.h-drugCard {
  display: flex;
  justify-content: space-between;
  background-color: $ccolor;
  overflow: hidden;
  .drugChildMainElement {
    flex-grow: 2;
    flex-shrink: 2;
    overflow-wrap: anywhere;
    font-weight: bold;
    background-color: $ccolorContrast;
    padding: 26px 0 26px 26px;
    &:not(.no-round) {
      border-radius: 0 10px 10px 0;
    }
  }
  .drugTools {
    width: 48px;
    color: #fff;
  }
  .drugChild {
    display: flex;
    justify-content: center;
    flex-direction: column;
    &.drugIcon {
      min-width: 72px;
      max-width: 72px;
      text-align: center;
      background-color: $ccolorContrast;
      box-shadow: 0px 0px 9px #aaa;
      z-index: 1;
    }
    &.drugChildTime {
      background-color: #fff;
      padding: 16px;
      font-weight: bold;
    }
  }
  @media (max-width: (650px)) {
    .h-drugCard {
      flex-wrap: wrap;
    }
    .drugChildMainElement {
      flex-basis: 100%;
    }
    .drugIcon {
      display: none;
    }
  }
}