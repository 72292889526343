@import '../../static/styles/vars.scss';
.h-drop-down {
  background-color: #fff !important;
  border: 1px solid #eee;
  border-radius: 8px !important;
  button {
    border-radius: 8px !important;
    background-color: #fff;
    transition: .2s background-color ease-in-out;
  }
  &:hover button {
    border-radius: 8px 8px 0 0 !important;
    background-color: $ccolor !important;
    color: $ccolorContrast
  }
  .w3-dropdown-content {
    border-radius: 8px 0 8px 8px !important;
    z-index: 100;
    box-shadow: 0 1px 1px #666;
    overflow: hidden;
  }
  .dropdown-option {
    cursor: pointer;
    transition: .2s background-color ease-in-out;
    &.dropdown-option-active {
      background-color: $ccolor;
      color: $ccolorContrast;
    }
    &:hover {
      background-color: $ccolorSoft;
      // color: $ccolorContrast;
    }
  }
}