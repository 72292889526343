@import '../../static/styles/vars.scss';

.MultiButton {
  width: 100%;
  margin: 15px 0;
  cursor: pointer;
  background-color: #eee;
  border: none;
  line-height: 4em;
  &.MultiButton-active {
    background-color: $ccolor;
    color: $ccolorContrast;
  }
}