.hamburgerMenu {
  position: absolute;
  right: 0;
  bottom: 0;
  top: 0;
  background-color: #fff;
  transition: .2s transform ease-in;
  z-index: 1;
  .menu-short-name {
    font-size: 20px;
  }
  .menu-list {
    flex-direction: column;
  }
}