@import './vars';
@import './icons';

.pointer {
  cursor: pointer;
}

.h-flex {
  display: flex;
  justify-content: space-between;
}
.h-flex-start {
  display: flex;
  justify-content: flex-start;
}
.h-flexGrow {
  flex-grow: 2;
}

.h-flex-align-center {
  align-items: center;
}

.h-flex-wrap {
  flex-wrap: wrap;
}

.h-flex-no-shrink {
  flex-shrink: 0;
}

.h-flex-gap {
  row-gap: $globalPadding;
  column-gap: $globalPadding;
}

h2 {
  text-wrap: pretty;
  hyphens: auto;
}

.checkBox {
  line-height: 20px;
  cursor: pointer;
  &::before {
    color: rgba(0, 0, 0, .1);
    content: '✓';
    line-height: 20px;
    border: 1px solid #000;
    width: 20px;
    height: 20px;
    display: inline-block;
    text-align: center;
    margin-right: 4px;
  }
  &:hover::before {
    color: #333;
    content: '✓';
  }
}

.checkBoxChecked {
  &::before {
    content: '✓';
    border: 1px solid #000;
    width: 20px;
    height: 20px;
  }
}

.deleteBox {
  line-height: 20px;
  cursor: pointer;
  &::before {
    content: '✖';
    color: rgba(0, 0, 0, .1);
    line-height: 20px;
    border: 1px solid #000;
    width: 20px;
    height: 20px;
    display: inline-block;
    text-align: center;
    margin-right: 4px;
  }
  &:hover::before {
    content: '✖';
    color: #333;
  }
}

.text-align-right {
  text-align: right;
}

.alert-box {
  border: 1px solid #8F0600;
  background-color: #8F060030;
  color: #4e0400;
  padding: 14px;
  margin-top: 16px;
  margin-bottom: 16px;

}

.warn-box {
  border: 1px solid #D0D10A;
  background-color: #D0D10A30;
  color: #3c3c05;
  padding: 14px;
  margin: 16px;
  // border-radius: 10px;
}

.info-box {
  border: 1px solid $ccolor;
  background-color: $ccolorContrast;
  color: $ccolor;
  padding: 14px;
  margin-top: 16px;
  margin-bottom: 16px;
}

.border-width-2 {
  border-width: 2px !important;
}

.neutral-box {
  padding: 14px;
  margin-top: 16px;
  margin-bottom: 16px;
}

.text-box {
  margin-top: $globalPadding;
  margin-bottom: $globalPadding *.5;
  // padding: 0.01em 16px;
}

.h-main-button {
  width: 100%;
  border-radius: 20px;
  margin-bottom: 15px;
  color: $primaryFg;
  background-color: $primaryBg;
  border: none;
  padding: 10px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
  transition: all .2s ease-in-out;
  &:hover {
    background-color: $primaryBgHoover; 
    box-shadow: 0 0 10px $ccolorContrastSoft;
   }
}

.button-primary {
  background-color: $primaryBg !important;
  color: $primaryFg;
  cursor: pointer;
  transition: all .2s ease-in-out;
  border: none;
  display: inline-block;
  padding: 8px 16px;
  vertical-align: middle;
  overflow: hidden;
  text-decoration: none;
  text-align: center;
  white-space: nowrap;
  box-shadow: 0 0 4px $ccolorContrastSoft;
  &:hover {
   background-color: $primaryBgHoover !important; 
   box-shadow: 0 0 10px $ccolorContrastSoft;
  }
}

.button-secondary {
  background-color: $secondaryBg !important;
  color: $secondaryFg;
  cursor: pointer;
  transition: all .2s ease-in-out;
  border: none;
  display: inline-block;
  padding: 8px 16px;
  vertical-align: middle;
  overflow: hidden;
  text-decoration: none;
  text-align: center;
  white-space: nowrap;
  box-shadow: 0 0 4px $ccolorContrastSoft;
  &:hover {
   background-color: $secondaryBgHoover !important; 
   box-shadow: 0 0 10px $ccolorContrastSoft;
  }
}

.button-danger {
  background-color: $dangerBg;
  color: $dangerFg;
  cursor: pointer;
  transition: all .2s ease-in-out;
  border: none;
  display: inline-block;
  padding: 8px 16px;
  vertical-align: middle;
  overflow: hidden;
  text-decoration: none;
  text-align: center;
  white-space: nowrap;
  box-shadow: 0 0 4px $ccolorContrastSoft;
  &:hover {
   background-color: $dangerBgHoover; 
  }
}

.button-tool {
  cursor: pointer;
  color: $ccolorContrast;
  background-color: transparent;
  border: none;
  transition: all .2s ease-in-out;
  &:hover {
    color: #000; 
   }
}

.label-primary {
  background-color: #fff;
  // background-color: $primaryBg !important;
  color: $primaryBg;
  font-weight: bold;
  transition: all .2s ease-in-out;
  border: none;
  display: inline-block;
  padding: 8px 16px;
  vertical-align: middle;
  overflow: hidden;
  text-decoration: none;
  text-align: center;
  white-space: nowrap;
  box-shadow: 0 0 4px $ccolorContrastSoft;
}

.label-secondary {
  background-color: #fff;
  // background-color: $secondaryBg !important;
  color: $ccolorContrastSoft;
  transition: all .2s ease-in-out;
  border: none;
  display: inline-block;
  padding: 8px 16px;
  vertical-align: middle;
  overflow: hidden;
  text-decoration: none;
  text-align: center;
  white-space: nowrap;
  box-shadow: 0 0 4px $ccolorContrastSoft;
}

.text-box-main {
  font-size: 1.5em;
}

.page-header {
  text-align: right;
  a {
    margin: 2px;
  }
}

.barcode-link-holder {
  a {
    text-decoration: none;
  }
}

.no-text-decoration {
  text-decoration: none;
}

@media print {
  .bookingSuccess {
    color: $fcolor !important;
  }
  .dontPrint {
    display: none;
  }
}

.doNotDisplay {
  display: none !important;
}

html {
  height: 100%;
  overflow: hidden;
  body {
    height: 100%;
    #root {
      height: 100%;
      overflow: hidden;
      position: relative;
    }
  }
}
.UserLayoutHolder {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  .h-header {
    flex-grow: 0;
    display: flex;
    background-color: $ccolor;
    color: $ccolorContrast;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: $globalPadding;
    min-height: $desktopHeaderHeight;
    align-items: center;
    .h-standardButton {
      display: flex;
      align-items: center;

    }
  }
  .h-search-content,
  .h-main-content {
    flex-grow: 2;
    overflow: auto;
  }
  
  .h-footer {
    flex-grow: 0;
  }
}


.h-bg-white-shadow {
  background-color: #fff;
  box-shadow: $shadow;
  color: $fcolor;
}

.h-bg-color {
  background-color: $ccolor;
  color: $ccolorContrast;
}

.h-list-item {
  border-radius: 10px;
  border-left: 12px solid $ccolor;
}

.h-list-item-grey {
  border-radius: 10px;
  border-left: 12px solid $semiColor;
  color: $semiColor;
}

.h-search-field-holder {
  padding: $globalPadding;
  input {
    width: 100%;
    margin: auto;
    display: block;
    border-radius: 20px;
    border: 2px solid $ccolor;
    line-height: 2.5em;
    padding: 0 26px;
  }
  .theSearchField {
    position: relative;
    .reset-button {
      position: absolute;
      right: 9px;
      top: 9px;
      color: $fcolor;
      cursor: pointer;
    }
  }
}

.SvgActive {
  color: $ccolor;
}
.SvgCompatibleFontSize {
  line-height: 24px;
  svg {
    position: relative;
    top: 7px;
  }
}
@import './mobilerules.scss';
@import './desktoprules.scss';


.triangle-holder {
  display: inline-block;
  .triangle {
    border-top: $triangleheight solid $ccolorContrast;
    border-left: .5 * $trianglewidth solid transparent;
    border-right: .5 * $trianglewidth solid transparent;
  }
}

.mobile-headheadline {
  font-size: 30px;
}

.page-headline {
  display: flex;
  position: relative;
  justify-content: space-between;
  padding-left: 16px;
  padding-right: 16px;
  &>div {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  &::after {
    content: '';
    position: absolute;
    left: 16px;
    right: 16px;
    height: 1px;
    bottom: 0;
    background-color: $semiColor;
  }
}

.magnify0-7 {
  transform: scale(.7);
}
.magnify0-8 {
  transform: scale(.8);
}
.magnify0-9 {
  transform: scale(.9);
}
.magnify1-1 {
  transform: scale(1.1);
}
.magnify1-2 {
  transform: scale(1.2);
}

.scrollY {
  overflow-y: auto;
  max-height: 100%;
}

.maxPageHeight {
  max-height: 100%;
}

.drugInteractionPage .notOnDrugInteraction {
  display: none;
}

.publicFooter {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}
