.FirebaseToast {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  transition: all .4s ease-in-out;
  transform: scaleY(0);
  background-color: #fff;
  border-bottom: 1px;
  box-shadow: 0 0 0px #000;
  z-index: 1000;
  transform-origin: 0 0;
  &.active {
    transform: scaleY(1);
    box-shadow: 0 0 10px #000;
  }
}