$ccolor: #0066ba;
$ccolorSoft: #0066ba80;
$ccolorContrast: #fff;
$fcolor: #000;
$ccolorContrastSoft: rgba(0, 0, 0, .1);
$primaryBg: $ccolor;
$primaryFg: $ccolorContrast;
$primaryBgHoover: $ccolorSoft;
$secondaryBg: $ccolorContrastSoft;
$secondaryFg: $fcolor;
$secondaryBgHoover: $ccolorContrast;
$dangerBg: #C91312;
$dangerFg: #fff;
$dangerBgHoover: #C9131280;
$semiColor: #808080;
$shadow: 0px 0px 10px $semiColor;
$globalPadding: 20px;
$backdropColor: #f6f6f8;
$mobileDesktopBreakpoint: 800px;
$desktopGridLeftWidth: 200px;
$desktopGridRightWidth: 30%;
// $desktopGridContentWidth: 100% - $desktopGridLeftWidth - $desktopGridRightWidth;
$desktopHeaderHeight: 86px;
$triangleheight: 10px;
$trianglewidth: 16px;