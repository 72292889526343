@import '../../static/styles/vars.scss';

.login-box {
  color: $ccolor;
  input {
    border: 2px solid $ccolor;
    background-color: $ccolorContrast;
    border-radius: 10px;
    margin-bottom: 10px;
  }
  label {
    font-size: .8em;
    margin-left: 15px;
  }
  button {
    width: 100%;
    border-radius: 20px;
    margin-bottom: 15px;
  }
}