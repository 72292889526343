.PictureList {
  .PLItem {
    overflow: hidden;
    margin-bottom: 2px;
    * {
      float: left;
      margin-right: 4px;
    }
    .thumb {
      width: 50px;
      height: 50px;
      background-position: center;
      background-size: cover;
    }
    .PLName {
      line-height: 50px;
      vertical-align: center;
    }
  }
}