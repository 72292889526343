@import '../../static/styles/vars';
$vertical_scroll_breakpoint: 700px;
.RightDrawer {
  background-color: #fff;
  position: absolute;
  right: 0;
  top: $desktopHeaderHeight;
  transform: translateX(100%);
  transition: .4s transform ease-in-out;
  bottom: 0;
  display: flex;
  flex-direction: column;
  // box-shadow: 0 0 10px #aaa;
  border-left: 2px solid #aaa;
  z-index: 4;
  @media (max-height: ($vertical_scroll_breakpoint)) {
    overflow: auto;
  }
  &.showDrawer {
    transform: translateX(0);
  }
  .d-Header, .d-Menu {
    border-bottom: 1px solid $semiColor;
  }
  .d-Header {
    text-align: center;
    padding-bottom: 32px;
    .d-Icon {
      line-height: 4em;
      img {
        max-width: 100px;
      }
    }
    .d-subinfo {
      font-size: .8em;
    }
  }
  .d-Menu {
    padding-top: 32px;
    padding-bottom: 32px;
    display: flex;
    justify-content: space-around;
  }
  .d-Content {
    padding: 16px;
    @media (min-height: ($vertical_scroll_breakpoint + 1px)) {
      overflow: auto;
    }
  }
  .d-Menuitem {
    box-shadow: 0 2px 5px 0 rgba(0,0,0,0.16), 0 2px 10px 0 rgba(0,0,0,0.12);
    padding: 6px;
    border-radius: 8px;
    cursor: pointer;
    border: 2px solid transparent;
    &:hover {
      border: 2px solid $ccolor;
    }
    &.d-Menu-active {
      background-color: $ccolor;
      color: $ccolorContrast;
      border: 2px solid $ccolor;
    }
  }
}
