@media (min-width:600px) {
  .TimeSlotDisplay {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }
}

.SlotDisplay {
  display: flex;
  justify-content: space-between;
}
.SlotEdit {
  flex-grow: 2;
}