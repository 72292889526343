// Icons:
.menu-icon {
  display: block;
  background-size: contain;
  background-repeat: no-repeat;
  width: 42px;
  height: 50px;
  background-position: center center;

  @media (max-width: 800px) {
    margin: auto;
  }
  @media (min-width: 801px) {
    margin-right: 6px;
  }
  
  &.display-no {
    display: none;
  }

  &.IHome {
    background-image: url('../icons/awesome-home.svg');
  }

  &.IDrugs {
    background-image: url('../icons/awesome-pills.svg');
  }

  &.IDrugPlan {
    background-image: url('../icons/material-schedule.svg');
  }

  &.IDrugCheck {
    background-image: url('../icons/awesome-check-double.svg');
  }
  &.IChat {
    background-image: url('../icons/material-chat.svg');
  }
  
}
