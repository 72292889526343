.chatter {
  position: relative;
  iframe {
    border: none;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
  }
  .chat-arrow {
    font-size: 200px;
    position: absolute;
    bottom: 30%;
    right: 30%;
    transform: rotate(45deg);
    opacity: .5;
    .layer1 {
      animation: animateturn 7s ease-in-out infinite;
      .layer2 {
        animation: animatescale 9s ease-in-out infinite;
        .layer3 {
          animation: animatetranslate 4s ease-in-out infinite;
        }
      }
    }
  }
}

@keyframes animateturn {
  0%, 100% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(2deg);
  }
}
@keyframes animatescale {
  0%, 100% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
}
@keyframes animatetranslate {
  0%, 100% {
    transform: translate3d(0, 0, 0);
  }
  50% {
    transform: translate3d(20px, 15px, 0);
  }
}
