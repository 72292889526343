@import '../../static/styles/vars.scss';
.home-profile-item {
  .profile-box {
    background-color: #fff;
    border-radius: 20px;
    min-height: 400px;
    .profile-avatar-box,
    .profile-name-box {
      color: #9bbef7;
    }
    .profile-name-box {
      border-bottom: 1px solid $semiColor;
      padding-bottom: 8px;
    }
  }
}