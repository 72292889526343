@import './vars';
@media (max-width: ($mobileDesktopBreakpoint)) {
  .h-header-desktop,
  .page-headline,
  .hideOnMobile {
    display: none;
  }
  .menu-list {
    flex-direction: row;
    justify-content: space-evenly;
    li a {
      border-radius: 20px;
      text-decoration: none;
    }
  }
  .hamburgerMenu {
    left: 0;
    transform: translate(0, 100%);
    &.openHamburgerMenu {
      transform: translate(0, 0%);
      transition: .2s transform ease-out;
    }
  }

  .text-box {
    padding: 0.01em 16px;
  }
  .RightDrawer {
    width: 100%;
  }

  .hamburgerMenu {
    font-size: 2em;
  }

  .h-footer {
    .menu-list li a {
      text-align: center;
    }
  }
}
