.profileSelector {
  padding-top: 10px;
  padding-bottom: 10px;
  .w3-dropdown-content {
    right: 0;
  }
  .h-drop-down > .w3-button {
    padding-left: 34px;
    background-image: url('../../static/icons/open-person.svg');
    background-repeat: no-repeat;
    background-position: 8px 8px;
    background-size: 22px;
  }
  flex-direction: row !important;
  align-items: stretch;
  &>.h-drop-down > button {
    height: 100%
  }
}