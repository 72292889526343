@import '../../static/styles/vars.scss';

.multibutton {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  border: 1px solid #eee;
  .multibutton-item {
    border: 1px solid 000;
    cursor: pointer;
    padding-left: 0;
    padding-right: 0;
    // flex-grow: 2;
    &.multibutton-item-active {
      background-color: $ccolor;
      color: $ccolorContrast;
      border: 1px solid $ccolor;
    }
    &.multibutton-item-inactive {
      border: 1px solid $ccolorContrastSoft;
      &:hover {
        border: 1px solid $ccolor;
      }
    }
    &:hover {
      background-color: $ccolorSoft;
      color: $ccolorContrast;
    }
  }
}