@import '../../static/styles/vars.scss';
.timePickerDD {
  .separator {
    margin: 0 6px;
  }
  select {
    padding: 6px;
    border: 1px solid $ccolorContrastSoft;
    background-color: $ccolorContrast;
    cursor: pointer;
    &:hover {
      border: 1px solid $ccolor;
    }
  }
}